import ErrorPage from './common/errorPage/errorPage';
import { Switch, Route, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StatusDashboard } from './statusDashboard/StatusDashboard';
import { EventHistoryPage } from './eventHistory/EventHistoryPage';
import { Page } from './common/Page';
import { IntlProvider } from 'react-intl';
import { translationsByLanguageCode } from '@amzn/ads_status_dashboard_website_common';
import Cookies from 'js-cookie';
import { rumService } from './config/rumHelper';
import { RTL_LOCALES } from './config/constants';

const queryClient = new QueryClient();
function App() {
  const [currentLocale, setCurrentLocale] = useState('en-US');
  const location = useLocation();
  // Page tracking effect
  useEffect(() => {
    if (location && rumService) {
      try {
        rumService.recordEvent('PageVisit', {
          path: location.pathname,
          locale: currentLocale,
          timestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error('Failed to record RUM event:', error);
      }
    }
  }, [location, currentLocale]);
  // Load language from cookie or default to 'en-US'
  useEffect(() => {
    const savedLocale = Cookies.get('locale');
    if (savedLocale) {
      setCurrentLocale(savedLocale);
    }
  }, []);

  const isRTL = RTL_LOCALES.has(currentLocale);
  const handleLanguageChange = (newLocale) => {
    setCurrentLocale(newLocale);
    Cookies.set('locale', newLocale, { expires: 365, path: '/' }); // Cookie valid for 365 days
    if (rumService) {
      try {
        rumService.recordEvent('LanguageChange', {
          eventName: `changeTo-${newLocale}`,
        });
      } catch (error) {
        console.error('Failed to record RUM event:', error);
      }
    }
  };
  const messages = translationsByLanguageCode(currentLocale);
  const messagesUS = translationsByLanguageCode('en-US');
  const mergedMessages = {
    ...messagesUS, // Fallback messages
    ...messages, // Overwrite with available translations for the current locale
  };

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'}>
      <IntlProvider messages={mergedMessages} locale={currentLocale} defaultLocale={'en-US'}>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route exact path="/">
              <Page content={<StatusDashboard />} currentLocale={currentLocale} handleChange={handleLanguageChange} />
            </Route>
            <Route exact path="/eventHistory/:id">
              <Page content={<EventHistoryPage />} currentLocale={currentLocale} handleChange={handleLanguageChange} />
            </Route>
            <Route path="*">
              <Page content={<ErrorPage />} currentLocale={currentLocale} handleChange={handleLanguageChange} />
            </Route>
          </Switch>
        </QueryClientProvider>
      </IntlProvider>
    </div>
  );
}

export default App;
